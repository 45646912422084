import React, { useState } from "react";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Button } from "react-bootstrap";
import { TextField } from "../Common/TextField";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";

function Login(props) {
  const validate = Yup.object({
    mobileNo: Yup.string().required("Mobile is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be atleast 6 characters long"),
  });
  // const currentYear = new Date().getFullYear();

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="backgroundscreen">
      <div className="bgcolorLogin"> </div>
      <div className="backgroundsecscn">
        <div className="logincontent12"></div>
        <div>
          <Row>
            <Col xs={12} sm={12} md={6} lg={7} xl={8}>
              <div className="loginseondsection">
                <img
                  src={require("../../../src/assets/img/iFinDEX Logo@3x.png")}
                  className="loginimages"
                  alt="login"
                />
                <div>
                  <p className="logintitle">iFinDEX DOP Admin Portal</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={5} xl={4}>
              <div className="loginform">
                <Formik
                  initialValues={{
                    mobileNo: "",
                    password: "",
                  }}
                  validationSchema={validate}
                  onSubmit={(value) => {
                    props.logins(value);
                  }}
                >
                  {(formik) => (
                    <div>
                      <div>
                        <Form>
                          <div className="formAlign1">
                            <div
                              className="lgn-usrname"
                              style={{ width: "100%" }}
                            >
                              <TextField
                                placeholder="Mobile Number"
                                name="mobileNo"
                                type="text"
                                {...formik.getFieldProps("mobileNo")}
                              />
                            </div>

                            <div style={{ display: "flex" }}>
                              <TextField
                                placeholder="Password"
                                name="password"
                                type={passwordType}
                                {...formik.getFieldProps("password")}
                              />

                              <span className="pswd-lgn">
                                {passwordType === "password" ? (
                                  <i
                                    className="fa fa-eye-slash pswd-lgn-icon"
                                    aria-hidden="true"
                                    onClick={togglePassword}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye pswd-lgn-icon"
                                    aria-hidden="true"
                                    onClick={togglePassword}
                                  ></i>
                                )}
                              </span>
                            </div>

                             <div>
                              <Link
                                to="/forgotpassword"
                                className="resetforgot"
                                // onClick={() => window.scrollTo(0, 0)}
                              >
                                 Forgot your Password ?
                              </Link>
                            </div>
                            <Button type="submit" className="contactpagebtn">
                              LOGIN
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
          <div className="footerclass">
        <React.Fragment>
          <Row>
            <Col sm = {6} md={7} lg={8} xl = {9} className="footerVersion">
              <span>
       
                {process.env.REACT_APP_VERSION}
              </span>
            </Col>
            <Col sm = {6} md={5} lg={4} xl = {3}>
              <img
                src={require("../../../src/assets/img/integro_logo.png")}
                className="loginfooterimages"
                alt="login footer"
              />
            </Col>
          </Row>
        </React.Fragment>
      </div>
        </div>
        
      </div>
   
    </div>
  );
}

export default Login;
