import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";

import Login from "../containers/LoginContainer";
import DefaultLayout from "../containers/DefaultLayout";
import ForgotPasswordContainer from "../containers/ForgotPasswordContainer";

function AppNavigator() {
  return (
    <HashRouter basename="/">
      <React.Suspense>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
              <Route
            exact
            path="/forgotpassword"
            name="Forgot Password"
            render={(props) => <ForgotPasswordContainer {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route path="/resetpassword" name="reset" component={DefaultLayout} />

          
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}
export default AppNavigator;
