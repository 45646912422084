import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "../../containers/LoginContainer/actions";
import Footer from "./DefaultFooter";

import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";

import { navLicensee } from "../../_nav";
import routes from "../../routes";

const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

function DefaultLayout(props) {
  const [loadingUserRole, setLoadingUserRole] = useState(true);
  const [userRole, setUserRole] = useState(null);

  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  const signOut = (e) => {
    e.preventDefault();
    props.logoutaction();
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role) {
      setUserRole(role);
    }
    setLoadingUserRole(false); 
  }, []);

  if (loadingUserRole) {
    return loading();
  }

  if (!userRole) {
    return <Redirect to="/login" />;
  }

  const filteredNavLicensee = {
    ...navLicensee,
    items: navLicensee.items.filter((item) => {
      if (item.name === "Import Parties" && userRole !== "admin") {
        return false; 
      }
      return true; 
    }),
  };

  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader
            onLogout={(e) => signOut(e)}
            location={props.location}
            unReadNotification={props.unReadNotification}
          />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav navConfig={filteredNavLicensee} {...props} router={router} />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <Container fluid>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) =>
                        localStorage.getItem("userToken") != null ? (
                          <route.component {...props} />
                        ) : (
                          <Redirect from="/" to="/login" />
                        )
                      }
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Suspense>
          </Container>
        </main>
        <AppAside fixed></AppAside>
      </div>
      <Footer />
    </div>
  );
}

function bindAction(dispatch) {
  return {
    logoutaction: () => {
      dispatch(logOut());
    },
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, bindAction)(DefaultLayout);
