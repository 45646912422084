import React from "react";

const Dashboard = React.lazy(() => import("./containers/DashboardContainer"));
const Login = React.lazy(() => import("./containers/LoginContainer"));
const CreateProject = React.lazy(() =>
  import("./containers/CustomerViewContainer")
);
const MyCases = React.lazy(() => import("./containers/MyCasesContainer"));
const CustomerDetails = React.lazy(() =>
  import("./containers/CustomerViewContainer")
);

const User = React.lazy(() =>
  import("./containers/UserContainer")
);
const AllReport = React.lazy(() =>import('./containers/AllReportContainer'))
const VerifiedReport = React.lazy(()=> import('./containers/VerificationReportContainer'))
const PendingReport = React.lazy(() => import('./containers/PendingReportContainer'))
const CustomerBulkAssign = React.lazy(() => import('./containers/CustomerBulkAssign'))
const CustomerImport = React.lazy(() => import('./containers/ImportCustomerContainer'))
const BulkUserAdd = React.lazy(() => import('./containers/BulkUserAddContainer'))
const ForgotPassword = React.lazy(() => import('./containers/ForgotPasswordContainer'))
const ResetPassword = React.lazy(() => import('./containers/ResetPasswordContainer'))

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/All Activities",
    name: "All Activities",
    component: AllReport,
  },
  {
    path: "/Verification Report",
    name: "Verification Report",
    component: VerifiedReport,
  }, 
  {
    path:"/report/Pending Report",
    name: "Pending Report",
    component: PendingReport,
  },  {
    path: "/my parties/:id",
    name: "My Party Details",
    component: CustomerDetails,
    exact: true,
  },
  {
    path: "/my parties",
    name: "My Parties",
    component: MyCases,
  },
  { path: "/login", name: "Login", component: Login },

  {
    path: '/Bulk Assign',
    name: 'Bulk Assign',
    component: CustomerBulkAssign
  },
  {
    path: '/User Import',
    name: 'User Import',
    component: BulkUserAdd
  },

  {
    path: "/createproject/:id",
    name: "Create Project",
    component: CreateProject,
  },
  {
    path: "/createproject",
    name: "Create Project",
    component: CreateProject,
  },
  
  {
    path: "/users",
    name: "User",
    component: User,
  },
  {
    path: "/Import Parties",
    name: "Import Parties",
    component: CustomerImport,
  },
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/resetpassword",
    name: "Forgot Password",
    component: ResetPassword,
  },
];

export default routes;
