import { takeEvery, put, call } from "redux-saga/effects";
import { RESET_PASSWORD_INIT_ACTION } from "./constant";
import * as actionTypes from "./constant";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import { push } from "connected-react-router";


function* commonFunction(value) {
  let token = localStorage.getItem("userToken");

  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` 
      },
      body: value.body ? value.body : null,
    });
    return response;
  } catch (error) {
     if (error.status === 401) {
      yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
    } 

    yield put({ type: `${value.failAction}`, error: error });
    throw error;
  }
}
function* resetPassword(value) {  
  const data = {newPassword: value?.value?.newPassword , oldPassword: value?.value?.oldPassword };  
  try {
    let params = {
      api: `${appConfig.ip}/users/resetPass`,
      method: "POST",
      failAction: actionTypes.RESET_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.RESET_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };

    const response = yield call(commonFunction, params);

    if (response.ok) {
      yield toast.success("Password changed successfully", { autoClose: 3000 });
      yield put({ type: `${actionTypes.RESET_PASSWORD_SUCCESS_ACTION}` });
      yield put(push("/dashboard"));

    } else {
      throw response;
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Old password Incorrect, Try again";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status === 404) {
      message = "Not Found";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else {
      message = "Unknown Error";
    }
    toast.error(message, { autoClose: 3000 });
    yield put({
      type: `${actionTypes.RESET_PASSWORD_FAIL_ACTION}`,
      error: error,
    });
  }
}
export function* resetPasswordVoucher() {
  yield takeEvery(RESET_PASSWORD_INIT_ACTION, resetPassword);
}
