import { takeEvery, put, call } from "redux-saga/effects";
import * as actionTypes from "./constant";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import { push } from "connected-react-router";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  try {
    let response ;
    if(value.initAction === actionTypes.RESET_NEW_PASSWORD_INIT_ACTION){
      const token = localStorage.getItem("accessToken");
      
      response = yield fetch(`${value.api}`, {
        method: `${value.method}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: value.body ? value.body : null,
      });
    } else {
      response = yield fetch(`${value.api}`, {
        method: `${value.method}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: value.body ? value.body : null,
      });
    }
    
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();
      if(value.successAction === actionTypes.OTP_VERIFICATION_SUCCESS_ACTION){
        localStorage.setItem("accessToken", resJSON.backendTokens.accessToken);
      }
      if(value.successAction === actionTypes.RESET_NEW_PASSWORD_SUCCESS_ACTION){
        localStorage.removeItem("accessToken");
      }
      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      if (value.body.pin !== "") {
        if (resJSON.ok === false && resJSON.attemptLeft >= 0) {
          throw resJSON;
        } else if (resJSON.ok === false && resJSON.attemptLeft < 0) {
          throw resJSON;
        }
      }
    }
  } catch (error) {
    let message;
    if (error.status === 400) {
      message = "Field must not be empty and Enter a valid input";
    } else if (error.status === 401) {
      message = "Enter valid pin";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else if (error.ok === false && error.attemptLeft >= 0) {
      message =
        "You have entered a wrong OTP. You have" +
        " " +
        error.attemptLeft +
        " " +
        "attempts left";
    } else if (error.status === 404) {
      if(value.failAction === actionTypes.OTP_VERIFICATION_FAIL_ACTION){
        message = "OTP has expired, Try resending the OTP";
      } else {
        message = "User not found!! Try again with the registered mobile number";
      }
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(
        `${message}`,
        { autoClose: 3000 },
        { transition: Flip }
      );
    }
    throw error;
  }
}

function* otpVerifyForgotPassword(value) {
  const data = { mobileNo: value.value.mobileNo, pin: value.value.otp.toString() };
  try {
    let params = {
      api: `${appConfig.ip}/auth/otp-login`,
      method: "POST",
      failAction: actionTypes.OTP_VERIFICATION_FAIL_ACTION,
      successAction: actionTypes.OTP_VERIFICATION_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);

    yield toast.success("Verified successfully", {
      autoClose: 3000,
    });
  } catch (err) {
    console.error(err);
    if (err.attemptLeft === 0) {
      yield toast.error(
        "Redirecting to login page, since you have used your maximum attempts",
        {
          autoClose: 3000,
          transition: Flip,
          className: "toastClass",
        }
      );
      yield put({
        type: actionTypes.REDIRECT_TO_LOGIN_INIT_ACTION,
      });
    }
  }
}

function* sendOtpForgotPassword(value) {
  const data = { mobileNo: value.value.mobileNo && value.value.mobileNo };  
  try {
    let params = {
      api: `${appConfig.ip}/commondata/sendOtp`,
      method: "POST",
      failAction: actionTypes.FORGOT_SEND_OTP_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.FORGOT_SEND_OTP_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("An OTP has been sent to the registered mobile number", {
      autoClose: 3000,
    });
  } catch (err) {
    console.error(err);
  }
}

function* resendOtpForgotPassword(value) {
  const data = { mobileNo: value.value.mobileNo };

  try {
    let params = {
      api: `${appConfig.ip}/commondata/resendOtp`,
      method: "POST",
      failAction: actionTypes.FORGOT_RESEND_OTP_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.FORGOT_RESEND_OTP_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("OTP resend to the registered  mobile number", {
      autoClose: 3000,
    });
  } catch (err) {
    console.error(err);
  }
}

function* reSetNewpassword(value) {
  const data = {
    mobileNo: value.value.mobileNo,
    password: value.value.password,
  };
  try {
    let params = {
      api: `${appConfig.ip}/users/forgotPass`,
      method: "POST",
      initAction: actionTypes.RESET_NEW_PASSWORD_INIT_ACTION,
      failAction: actionTypes.RESET_NEW_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.RESET_NEW_PASSWORD_SUCCESS_ACTION,      
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Password changed successfully", {
      autoClose: 3000,
    });
    yield put(push("/login"));
  } catch (err) {
    console.error(err);
  }
}
function* redirectTologin() {
  yield put({
    type: actionTypes.REDIRECT_TO_LOGIN_SUCCESS_ACTION,
  });
  yield put(push("/login"));
}

export function* ForgotResetPwdActionWatcher() {
  yield takeEvery(
    actionTypes.FORGOT_SEND_OTP_PASSWORD_INIT_ACTION,
  sendOtpForgotPassword
  );
  yield takeEvery(
    actionTypes.FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION,
    resendOtpForgotPassword
  );
  yield takeEvery(
    actionTypes.OTP_VERIFICATION_INIT_ACTION,
    otpVerifyForgotPassword
  );

  yield takeEvery(actionTypes.RESET_NEW_PASSWORD_INIT_ACTION, reSetNewpassword);
  yield takeEvery(actionTypes.REDIRECT_TO_LOGIN_INIT_ACTION, redirectTologin);
}
