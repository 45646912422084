import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  customerList: [],
  customerCount: 0,
  error: false,
  stateList:[],
  stateCount:0,
  districtList:[],
  districtCount:0,
  customerReportData: [],
  partyFilterData: {
    state: '',
    district: '',
    status: '',
    allocation: '',
    assignTo: '',
    pincode: '',
    searchItem: '',
  },
 partyChangelog: [],
editCustomerResult: false,

};
export default function MyCasesReducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.FETCH_CUSTOMERLIST_SUCCESS_ACTION:
      
      return {
        ...state,
        customerList: action.payload.customers
          ? action.payload.customers
          : action.payload.assignedCustomers,

        customerCount: action.payload.totalCount,
      };

    case actionTypes.CHANGE_STATUS_PROJECT_SUCCESS_ACTION:
      return {
        ...state,
        customerList: state.customerList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        customerCount: state.customerCount,
      };
      case actionTypes.FETCH_STATE_SUCCESS_ACTION:        
        return {
          ...state,
          stateList: action.payload.state,
          stateCount: action.payload.totalCount,
        };
      case actionTypes.FETCH_DISTRICT_SUCCESS_ACTION:
        return {
          ...state,
          districtList: action.payload.district,
          districtCount: action.payload.totalCount,
        };
      case  actionTypes.ADD_PARTY_FILTER_DATA:
        return {
          ...state.partyFilterData,
           ...action.payload
        }
      case actionTypes.EXPORT_ALL_CUSTOMER_SUCCESS_ACTION:
        return {
          ...state,
          customerReportData:action.payload.customers
          ? action.payload.customers
          : action.payload.assignedCustomers,
        }
      case actionTypes.EXPORT_ALL_CUSTOMER_INIT_ACTION:
        return{
          ...state,
          customerReportData: action.payload
        }
  case actionTypes.FETCH_PARTY_CHANGELOGBYID_SUCCESS_ACTION:
    return {
      ...state,
      partyChangelog: action.payload.updates
    }
    case actionTypes.FETCH_PARTY_CHANGELOGBYID_INIT_ACTION:
      return {
        ...state,
        partyFilterData: action.payload
      }
    case actionTypes.PARTY_EDITBYID_SUCCESS_ACTION:
      return {
        ...state,
        editCustomerResult: action.payload
      }

    default:
      return {
        ...state,
      };
  }
}
